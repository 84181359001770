.dashboard-container {
    font-family: 'Roboto', sans-serif;
    background-color: #1a1a2e; /* Dark blue/black background */
    color: #e0e0e0; /* Light text for contrast */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.5); /* Smooth shadow for depth */
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 10px;
  }
  
  .dashboard-container h1 {
    font-size: 28px;
    color: #00d4ff; /* Bright blue title */
    text-align: center;
    margin-bottom: 20px;
  }
  
  .dashboard-container select,
  .dashboard-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    background-color: #162447; /* Dark blue input background */
    color: #e0e0e0; /* Light text */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
  }
  
  .dashboard-container select:focus,
  .dashboard-container input:focus {
    outline: none;
    background-color: #0f3460; /* Slightly brighter on focus */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.6);
  }
  
  .stock-details {
    background-color: #0f3460; /* Slightly lighter blue box */
    padding: 15px;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.4);
    color: #e0e0e0; /* Ensure text is visible */
  }
  
  .stock-details p {
    margin: 10px 0;
  }
  
  .stock-details strong {
    color: #00d4ff; /* Blue highlight for important text */
  }
  
  .alert {
    background-color: #ff4d4d; /* Red background for errors */
    color: white;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
  }
  
  .dashboard-container p {
    font-size: 14px;
    color: #e0e0e0;
  }
  
  button {
    background-color: #00d4ff;
    color: white;
    border: none;
    padding: 12px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    transition: all 0.3s ease;
  }
  
  button:hover {
    background-color: #00aaff;
  }
  
  .dashboard-container .chart-container {
    margin: 20px 0;
    background-color: #162447; /* Dark blue box for the chart */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
  }
  