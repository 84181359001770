/* Ensure buttons in Header.js have consistent sizing and appearance */
.header-buttons .btn {
    width: auto; /* Make sure width is determined by content */
    padding: 10px 20px; /* Proper padding for a normal button size */
    font-size: 16px; /* Ensure readable text size */
    border-radius: 6px; /* Rounded corners for a modern look */
    margin: 0 10px; /* Add spacing between buttons */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .header-buttons .btn-primary {
    background-color: #007bff; /* Primary blue color for Log In */
    color: white;
  }
  
  .header-buttons .btn-primary:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .header-buttons .btn-outline-primary {
    color: #007bff; /* Outline button text color */
    border: 2px solid #007bff;
  }
  
  .header-buttons .btn-outline-primary:hover {
    background-color: #007bff;
    color: white;
  }
  
  .header-buttons .btn-danger {
    background-color: #dc3545; /* Red for Log Out */
    color: white;
  }
  
  .header-buttons .btn-danger:hover {
    background-color: #c82333; /* Darker red on hover */
  }
  
  .header-buttons .btn-outline-light {
    color: white; /* White text for light outline button */
    border: 2px solid white;
  }
  
  .header-buttons .btn-outline-light:hover {
    background-color: white;
    color: #343a40; /* Dark text on hover */
  }
  
  /* Ensure buttons are responsive */
  @media (max-width: 768px) {
    .header-buttons .btn {
      width: 100%; /* Full width for mobile */
      margin: 5px 0; /* Vertical margin between buttons */
    }
  }
   /* Header Styles */
/* Base Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
}

.logo {
  margin-right: 10px;
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* Hamburger Menu Styles */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
  margin-left: auto; /* Pushes the hamburger button to the right side */
}

.hamburger {
  width: 25px;
  height: 3px;
  background-color: white;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Make the navbar a flex container to align items horizontally */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu-toggle {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: auto;
  }

  .nav-menu {
    display: none;
    flex-direction: column;
    background-color: #444;
    position: absolute;
    top: 60px;
    right: 10px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  .nav-menu.show {
    display: flex;
  }

  .logged-in-menu,
  .logged-out-menu {
    flex-direction: column;
    gap: 10px;
  }

  .btn {
    width: 100%;
  }
}

/* Dropdown Alignment */
.dropdown-toggle {
  width: auto;
  margin-right: 10px;
}

