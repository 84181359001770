/* CurrencyConverter.css */
.currency-converter-container {
  background-color: #000000;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  max-width: 600px;
  margin-top: 200px;
  margin-bottom: 50px;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.currency-converter h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #0a1179; /* Teal color for header */
}

.currency-search {
  margin-bottom: 20px;
  position: relative;
}

.currency-search input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
}

.currency-search input:focus {
  border-color: #052280; /* Teal focus border */
}

.dropdown-list {
  position: absolute;
  top: 110%;
  left: 0;
  right: 0;
  background-color: #333;
  border: 1px solid #050d75;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #fff;
}

.dropdown-item:hover {
  background-color: #7170c0; /* Teal on hover */
  color: #000;
}

label {
  font-size: 1.1rem;
  margin-bottom: 5px;
  display: block;
  color: #aaa;
}

input[type="number"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease;
}

input[type="number"]:focus {
  border-color: #08126e;
}

button {
  width: 100%;
  padding: 10px;
  font-size: 1.1rem;
  background-color: #0a0457;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #000000;
}

.conversion-result {
  background-color: #333;
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  color: #c2c046;
  font-size: 1.5rem;
}

.error-message {
  background-color: #b00020;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
}

.conversion-history {
  margin-top: 20px;
}

.conversion-history h4 {
  color: #ffffff;
  font-size: 1.2rem;
}

.conversion-history ul {
  list-style-type: none;
  padding: 0;
}

.conversion-history li {
  background-color: #333;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #fff;
}
