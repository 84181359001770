/* General styling for the account container */
.account-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  }
  
  /* Dark mode styling for the account container */
  .account-container.dark-mode {
    background-color: #2c2c2c !important;
    color: #ffffff;
  }
  
  /* Account details styling */
  .account-details {
    text-align: center;
  }
  
  .profile-pic {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  /* Edit form styling */
  .account-edit-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-weight: bold !important;
    margin-bottom: 5px !important;
    display: block !important;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100% !important;
    padding: 10px !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
  }
  
  input[type="file"] {
    margin-top: 10px !important;
  }
  
  .profile-pic {
    width: 150px; /* Adjust the width as necessary */
    height: 150px; /* Adjust the height as necessary */
    border-radius: 50%; /* Make it circular if needed */
    object-fit: cover; /* Ensure the image fits within the box */
    margin-top: 10px;
  }
  
  .profile-pic-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-top: 10px;
  }
  
  /* Dark mode styling for form elements */
  .account-edit-form.dark-mode input[type="text"],
  .account-edit-form.dark-mode input[type="email"],
  .account-edit-form.dark-mode textarea {
    background-color: #444;
    color: #fff;
    border: 1px solid #666;
  }

  .btn {
    padding: 10px 20px !important;
    border: none !important;
    border-radius: 4px !important;
    cursor: pointer !important;
  }

  
  .btn-save {
    background-color: #28a745 !important;
    color: #fff !important;
    margin-bottom: 10px !important;
  }
  
  .btn-cancel {
    background-color: #dc3545 !important;
    color: #fff !important;
  }
  
  .btn-edit {
    background-color: #007bff !important;
    color: #fff !important;
  }
  
  .btn-save:hover,
  .btn-cancel:hover,
  .btn-edit:hover {
    opacity: 0.9 !important;
  }
  
  /* Dark mode button styling */
  .btn-save.dark-mode {
    background-color: #218838;
  }
  
  .btn-cancel.dark-mode {
    background-color: #c82333;
  }
  
  .btn-edit.dark-mode {
    background-color: #0056b3;
  }