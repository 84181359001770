.App {
  text-align: center;
  background-color: rgb(255, 255, 255);
  justify-content: space-between !important;
}

.App.dark-mode {
  background-color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

body.dark-mode {
  background: #000000;
}

.hero-section {
  height: 80vh;
  background: linear-gradient(to right, #007bff, #6610f2);
  color: white;
}

.hero-section h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
}

.hero-section.dark-mode {
  background: linear-gradient(to right, #020485, #010541);
}

.features-section {
  padding: 50px 0;
}


.footer {
  padding: 20px;
  background: #333;
  color: #fff;
}
/* Adjust the navbar logo, title, and button */
.navbar {
  padding: 15px 30px;
  background-color: #182533;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.navbar-brand {
  
  display: flex;
  align-items: left;  /* Align logo and title in a row */
}

.navbar-brand img {
  margin-right: 5px !important;  /* Space between logo and title */
}

.navbar-brand h1 {
  background: linear-gradient(90deg, #007bff, #6610f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 0;
  font-size: 1.8rem;
  /*text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);*/
}


/* Default (Light Mode) */
body {
  background-color: #ffffff;
  color: #000000;
}


.navbar-brand .heading {
  margin-left: 0 !important;
}

.hero-section {
  background: linear-gradient(to right, #007bff, #6610f2);
  color: white;
}

/* Dark Mode */
body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.navbar.dark-mode {
  background: linear-gradient(to right, #211f25, #000000);
}

.main-view {
    background-image: url('../assets/main-bg.jpg'); /* Use a relative or absolute path */
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents tiling */
    height: 100vh; /* Adjust based on your needs */
    color: white; /* Optional: Ensure text is visible over the image */
    display: flex; /* Optional: Center content */
    align-items: center;
    justify-content: center;
}